import { doRequest, fetchIri } from '@/api/utils';

export async function getItem($axios, id) {
  const { data } = await doRequest($axios, 'GET', `/items/${id}`);
  return data;
}

export async function getItemFromIri($axios, iri) {
  return await fetchIri($axios, 'GET', iri);
}

export async function getItemFromSlug($axios, slug) {
  const { data } = await doRequest($axios, 'GET', `/items/slug/${slug}`);
  return data;
}

export async function postItem($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/items', postData);
  return data;
}

export async function updateItem($axios, id, postData) {
  const { data } = await doRequest($axios, 'PATCH', `/items/${id}`, postData, {
    headers: { 'Content-Type': 'application/merge-patch+json' }
  });
  return data;
}

export async function withdrawItem($axios, id) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/withdraw`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function publishItem($axios, id) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/publish`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function archiveItem($axios, id) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/archive`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function setItemSold($axios, id, userId = null) {
  const params = {};
  userId && (params.soldTo = `/users/${userId}`);
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/sold`,
    params,
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getBrands($axios, search) {
  const params = {};
  if (search) {
    params['name'] = search;
  }
  const { data } = await doRequest($axios, 'GET', '/brands', {}, { params });
  return data;
}

export async function getColors($axios) {
  const { data } = await doRequest($axios, 'GET', '/colors');
  return data;
}

export async function getSizes($axios, categoryIri, page = null) {
  const params = {};
  if (categoryIri) {
    params['taxons'] = categoryIri;
  }
  if (page) {
    params.page = page;
  }
  const { data } = await doRequest($axios, 'GET', '/sizes', {}, { params });
  return data;
}

export async function getItemConditions($axios) {
  const { data } = await doRequest($axios, 'GET', '/item-conditions');
  return data;
}

export async function getParcelSizes($axios) {
  const { data } = await doRequest($axios, 'GET', '/parcel-sizes');
  return data;
}

export async function search($axios, params, algolia = false) {
  const endpoint = !algolia ? '/items' : '/items/alg';
  const { data, headers } = await doRequest(
    $axios,
    'GET',
    endpoint,
    {},
    { params }
  );
  const queryID = headers['x-algolia-queryid'] || null;
  return { data, queryID };
}

export async function searchAggs($axios, params, algolia = false) {
  const endpoint = !algolia ? '/item-aggregations' : '/item-aggregations/alg';
  const { data } = await doRequest($axios, 'GET', endpoint, {}, { params });
  return data;
}

export async function getPopularItems(
  $axios,
  page = null,
  perPage = null,
  randomize = false
) {
  const params = {};
  page && (params.page = page);
  perPage && (params.itemsPerPage = perPage);
  randomize && (params.randomize = 1);
  const { data } = await doRequest(
    $axios,
    'GET',
    '/items/popular',
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function getMostLiked($axios, page = null) {
  const params = {};
  if (page) {
    params.page = page;
  }
  const { data } = await doRequest(
    $axios,
    'GET',
    '/items/most-liked',
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function getLatestPublished($axios, page = null, limit = null) {
  const params = {};
  limit && (params.itemsPerPage = limit);
  page && (params.page = page);
  const { data } = await doRequest(
    $axios,
    'GET',
    '/items/latest-published',
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function getSimilarItems($axios, itemId, page = null) {
  const params = {};
  if (page) {
    params.page = page;
  }
  const { data } = await doRequest(
    $axios,
    'GET',
    `/items/${itemId}/similar-items`,
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function getItemAlertReasons($axios, page = null) {
  const params = {};
  if (page) {
    params.page = page;
  }
  const { data } = await doRequest(
    $axios,
    'GET',
    '/item-alert-reasons',
    {},
    { params }
  );
  return page ? data : data['hydra:member'] || null;
}

export async function postItemAlert($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/item-alerts', postData);
  return data || null;
}

export async function getPriceAlerts($axios, userId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/price-alerts`
  );
  return data || null;
}

export async function postPriceAlert($axios, postData) {
  const { data } = await doRequest($axios, 'POST', '/price-alerts', postData);
  return data || null;
}

export async function resellItem($axios, id) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/resell`,
    {},
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function eventViewItem($axios, id, queryID = null) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/events/viewed`,
    { queryID },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function eventClickItem(
  $axios,
  id,
  queryID = null,
  position = null
) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/events/clicked`,
    { queryID, position },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}

export async function getItemLastOffer($axios, userId, itemId) {
  const { data } = await doRequest(
    $axios,
    'GET',
    `/users/${userId}/items/${itemId}/last-offer`
  );
  return data;
}

export async function getBoosts($axios) {
  const { data } = await doRequest($axios, 'GET', '/boosts');
  return data['hydra:member'] || [];
}

export async function boostItem($axios, id, boost) {
  const { data } = await doRequest(
    $axios,
    'PATCH',
    `/items/${id}/boost`,
    { boost },
    {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    }
  );
  return data;
}
