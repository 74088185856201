<template>
  <GenericModal
    ref="modal"
    :width="width"
    :loading="loading"
    :body-padding="20"
    @opened="$emit('opened', $event)"
    @closed="onClosed"
  >
    <div class="free-fees-modal">
      <div class="free-fees-modal__container">
        <Button
          theme="transparent"
          class="free-fees-modal__close"
          @click="hide"
        >
          <CrossSvg class="modal__close__svg" />
        </Button>
        <div class="free-fees-modal__heading">
          {{ $t('components.free-fees-modal.heading') }}
        </div>
        <div
          class="free-fees-modal__text"
          v-html="$t('components.free-fees-modal.text1')"
        />
        <div class="free-fees-modal__sub-text">
          {{ $t('components.free-fees-modal.text2') }}
        </div>
        <div class="free-fees-modal__illustration">
          <img src="~/assets/img/misc/woman-taking-photo.jpg" alt="" />
        </div>
      </div>
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/Modal/GenericModal';
import Button from '@/components/Button/Button';
import CrossSvg from '~/assets/img/cross.svg?inline';

export default {
  name: 'FreeFeesModal',
  components: { GenericModal, Button, CrossSvg },
  props: {
    width: {
      type: Number,
      default: 656
    },
    ctaLabel: {
      type: String,
      default: null
    },
    ctaTheme: {
      type: String,
      default: 'orange'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ threshold: null }),
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    onClosed() {
      window.localStorage.setItem('free-fees:reminder:last-closed', Date.now());
    }
  }
};
</script>

<style lang="scss" scoped src="./free-fees-modal.scss"></style>
