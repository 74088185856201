import { doRequest } from '@/api/utils';

export async function getBlock($axios, blockName) {
  const { data } = await doRequest(
    $axios,
    'GET',
    '/merch-blocks',
    {},
    { params: { code: blockName } }
  );
  return data['hydra:member'][0] || null;
}

export async function getSelection($axios, selectionName) {
  const { data } = await doRequest(
    $axios,
    'GET',
    '/merch-item-selections',
    {},
    { params: { code: selectionName } }
  );
  return data['hydra:member'][0] || null;
}

export async function getSelectionItems($axios, selectionId, perPage = null) {
  const params = {};
  perPage && (params.itemsPerPage = perPage);
  const { data } = await doRequest(
    $axios,
    'GET',
    `/merch-item-selections/${selectionId}/items`,
    {},
    { params }
  );
  return data['hydra:member'] || null;
}
