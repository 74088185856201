<template>
  <div class="carousel-container" :style="{ height: `${height}px` }">
    <template v-if="slides && slides.length">
      <Hooper
        v-if="slides.length > 1"
        class="carousel"
        v-bind="$attrs"
        :wheel-control="false"
        :style="{ height: `${height}px` }"
      >
        <Slide v-for="(slide, index) in slides" :key="index" :index="index">
          <template v-if="slideTemplate === 'hero'">
            <Hero :data="slide" :height="height" class="carousel__slide" />
          </template>
        </Slide>
      </Hooper>
      <div
        v-else-if="slideTemplate === 'hero'"
        class="carousel"
        :style="{ height: `${height}px` }"
      >
        <Hero :data="slides[0]" :height="height" class="carousel__slide" />
      </div>
    </template>
  </div>
</template>

<script>
import Hero from './templates/Hero';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Carousel',
  components: {
    Hooper,
    Slide,
    Hero
  },
  props: {
    slides: {
      type: Array,
      required: true
    },
    slideTemplate: {
      type: String,
      default: 'hero'
    },
    height: {
      type: Number,
      default: 400
    }
  }
};
</script>

<style lang="scss" scoped src="./carousel.scss"></style>
