<template>
  <div class="hero" :style="{ height: `${height}px` }">
    <img
      draggable="false"
      :src="data.contentUrl"
      :alt="data.title"
      class="hero__image"
    />
    <div class="hero__content-wrapper container responsive-pad">
      <div class="hero__content">
        <div class="hero__content__title">
          {{ data.title }}
        </div>
        <div class="hero__content__text" v-html="data.content" />
        <Button
          v-if="data.cta && data.ctaUrl"
          class="hero__content__button"
          :to="localePath({ name: 'AddProduct' })"
        >
          {{ data.cta }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';

export default {
  name: 'Hero',
  components: { Button },
  props: {
    data: { type: Object, required: true },
    height: { type: Number, default: 400 }
  }
};
</script>

<style lang="scss" scoped src="./hero.scss"></style>
